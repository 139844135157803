<template>
  <div>
    <div v-for="(update, index) in ezygoupdates" :key="index">
      <b-card class="updates-card">
        <div class="updates-date">Date: {{ formatDate(update['date_time']) }}</div>
        <h1 class="updates-title" v-html="update['heading']"></h1>
        <div class="update-description" v-html="update['description']"></div>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    this.getEzygoUpdates();
  },
  data() {
    return {
      ezygoupdates: [],
    };
  },
  methods: {
    async getEzygoUpdates() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/institutionuser/ezygoupdates`;

      try {
        const response = await axios.get(url);
        this.ezygoupdates = response.data;
      } catch (error) {
        console.error("Error fetching updates:", error);
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    },
  },
};
</script>

<style scoped>
.updates-card {
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fafafa;
}

.updates-date {
  text-align: left;
  color: red;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
  margin-bottom: 10px;
}

.updates-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.update-description {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.update-description ul {
  padding-left: 20px;
}

.update-description li {
  margin-bottom: 5px;
}
</style>
